import React from 'react';
import dayjs from 'dayjs';

import { Controller } from 'react-hook-form';

function DateInput({ control, name }: any) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={new Date()}
      render={({ field }) => (
        <input
          {...field}
          type="date"
          min={dayjs().format('YYYY-MM-DD')}
          value={dayjs(field.value).format('YYYY-MM-DD')}
          onChange={(e) => field.onChange(e)}
        />
      )}
    />
  );
}

export default DateInput;
