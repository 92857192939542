import { useForm } from 'react-hook-form';
// import React from 'react';
// import { SubmitHandler, useForm } from 'react-hook-form';
// import { useDebounce } from 'react-use';

// interface IFormOnChangeData {
//   values: Record<string, any>;
//   isDirty: boolean;
//   isValid: boolean;
// }

// type IFormOnChange = (callback: IFormOnChangeData) => any;

// const isSelectValue = (value: any) => {
//   if (!value || typeof value === 'string') {
//     return false;
//   }
//   const keys = Object.keys(value);
//   return (
//     !!(keys.length === 2 && 'value' in value && 'label' in value) ||
//     !!(keys.length === 3 && 'value' in value && 'label' in value && '__isNew__' in value)
//   );
// };

// const enhanceDataRecursively = (data: any) => {
//   if (!data) {
//     return data;
//   }
//   const newData: any = {};
//   for (let [key, value] of Object.entries(data) as any[]) {
//     if (typeof value !== 'undefined') {
//       if (Array.isArray(value)) {
//         let isListOfSelect = false;

//         value.forEach((val: any) => {
//           if (isSelectValue(val)) {
//             isListOfSelect = true;
//             newData[key] = [...(newData[key] || []), val.value];
//           }
//         });

//         if (!isListOfSelect) {
//           newData[key] = value.map(enhanceDataRecursively);
//         }
//       } else if (value === null) {
//         newData[key] = value;
//       } else if (typeof value === 'object' && !(typeof File !== 'undefined' && value instanceof File)) {
//         if (isSelectValue(value)) {
//           newData[key] = value.value;
//         } else {
//           const newValue = enhanceDataRecursively(value);

//           if (newValue) {
//             newData[key] = newValue;
//           }
//         }
//       } else {
//         newData[key] = value;
//       }
//     }
//   }

//   return newData;
// };

// // FIXME import/no-anonymous-export should be handled with nice typescript types
// // eslint-disable-next-line
// export default (...args: Parameters<typeof useForm>) => {
//   const props = useForm(...args);

//   const getSubmitValues = (data?: Record<string, any>) => enhanceDataRecursively({ ...(data || props.getValues()) });

//   // this piece of code surcharges the regular handleSubmit because when we use
//   // react-select, the data we get back is an object or an array of objects
//   // and not a string of array of strings as expected
//   const enhancedSubmit = (callback: SubmitHandler<Record<string, any>>) =>
//     props.handleSubmit(async (data: Record<string, any>, e?: React.BaseSyntheticEvent) => {
//       e && e.preventDefault();
//       e && e.stopPropagation();
//       const newData = getSubmitValues(data);

//       // everytime we submit, we should reset the fields so that form does not appear dirty anymore
//       const response = await callback(newData, e);

//       props.reset(props.getValues());

//       return response;
//     });

//   // wrapper function to easily set all fields to empty values
//   const resetAllFields = (callback: (fieldsReset: any) => any) => () => {
//     const allFieldsReset = Object.keys(props.getValues()).reduce((acc, field) => ({ ...acc, [field]: null }), {} as any);
//     props.reset(allFieldsReset);
//     return callback({ ...allFieldsReset, filtered: true });
//   };

//   // wrapper function to easily set all fields and append a filtered value
//   // we use this for filter forms that have default config
//   const resetAllFilters = (callback: (fieldsReset: any) => any) => () => {
//     callback({ ...resetAllFields((val: any) => val)(), filtered: true });
//   };

//   const isFormEmpty = () => Object.values(props.getValues()).filter((val: any) => !!val).length === 0;

//   // We watch all fields https://react-hook-form.com/api#watch
//   // This is necesary initial for Form Radio to be able to re-render everytime
//   // we use `control.setValue()`
//   // Not sure this is the best way to go though but it works
//   props.watch();

//   const { isDirty, isValid } = props.formState;
//   const submitValues = getSubmitValues(); // values with only ids instead of whole nested object

//   // removed by @martin because on CSV report, it lead to an unsubmitable form
//   // when only default values were set
//   // this might have some side effects because this was here for a reason
//   // React.useEffect(() => {
//   //   // Sometimes the isDirty flag remains true even if there are no dirtyFields anymore
//   //   // this fixes it
//   //   if (isDirty && isEmpty(dirtyFields)) {
//   //     // props.reset(props.getValues());
//   //   }
//   // }, [dirtyFields, isDirty, props]);

//   const useOnChange = (callback?: IFormOnChange, time = 500, dependencies: any[] = []) =>
//     useDebounce(
//       () =>
//         callback &&
//         callback({
//           values: submitValues,
//           isDirty,
//           isValid,
//         }),
//       time,
//       [submitValues, isDirty, isValid, ...dependencies]
//     );

//   // extend control to be able to clear a value from within a controller (initially with ColorPicker)
//   const control: typeof props.control & {
//     clearErrors?: typeof props.clearErrors;
//     clearError?: (name: string) => void;
//   } = props.control;
//   control.clearErrors = props.clearErrors;
//   control.clearError = (name: string) => props.setError(name, {});

//   return {
//     ...props,
//     control,
//     getSubmitValues,
//     resetAllFields,
//     useOnChange,
//     resetAllFilters,
//     isFormEmpty,
//     handleSubmit: enhancedSubmit,
//   };
// };

export default useForm;
