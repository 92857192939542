import 'react-phone-input-2/lib/style.css';
import React from 'react';
import PhoneInput from 'react-phone-input-2';

import { Controller } from 'react-hook-form';

import fr from 'react-phone-input-2/lang/fr.json';

// https://github.com/bl00mber/react-phone-input-2
function FormPhoneInput({ control, name }: any) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <PhoneInput
          country={'fr'}
          onChange={(value) => onChange(value.startsWith('+') ? value : value.startsWith('0') ? `+33${value.substring(1)}` : `+${value}`)}
          inputProps={{ value }}
          localization={fr}
          placeholder="Numéro de téléphone"
          inputStyle={{ height: '2rem' }}
          priority={{ fr: 1 }}
          searchNotFound={'Aucune entrée à afficher'}
        />
      )}
    />
  );
}

export default FormPhoneInput;
