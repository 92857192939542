import ReactDOMServer from 'react-dom/server';
import { useUpload } from 'modules/upload';
import { useToggle } from 'react-use';

type UploadProps = {
  onUpload: (url: string) => any;
  accept: string;
  multiple?: boolean;
  folder?: string;
  filename?: string;
  type?: 'file' | 'hidden';
} & React.HTMLAttributes<HTMLElement>;

const Upload: React.FC<UploadProps> = ({ children, folder, filename, onUpload, ...props }) => {
  let { upload, openFileDialog, FileInput } = useUpload();
  const [uploading, toggleUploading] = useToggle(false);

  const handleFileChange = async (eventOrFile: File | any) => {
    toggleUploading(true);
    let file: File = eventOrFile;
    if (eventOrFile.target) {
      const files: File[] = Array.from(eventOrFile?.target.files);
      file = files[0];
    }

    const { url } = await upload(file, { folder: folder || '_tmp', filename: filename || '' });

    await onUpload(url);

    setTimeout(() => {
      toggleUploading(false);
    }, 1000);
  };
  if (!children || (ReactDOMServer.renderToString(<>{children}</>) === '' && !uploading)) {
    return <input type={'file'} onChange={handleFileChange} {...props} />;
  }

  return (
    <div>
      <FileInput onChange={handleFileChange} {...props} />
      {children}
      {uploading ? (
        <div className="loader"></div>
      ) : (
        <button type="button" className="link text-xs" onClick={openFileDialog}>
          Modifier
        </button>
      )}
    </div>
  );
};

export default Upload;
