import React from 'react';
// import { components, type InputProps } from "react-select";
import { useToggle } from '@react-hookz/web';
import classNames from 'classnames';
import { useQuery } from 'modules/GraphQL';
import AsyncSelect from 'react-select/async';
import AsyncSelectCreatable from 'react-select/async-creatable';

export const styles = {
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  input: (provided: any) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  control: (provided: any) => ({
    ...provided,
    '&:hover': {
      ...provided['&:hover'],
      borderRadius: 0,
    },
    minWidth: 200,
    borderRadius: 0,
    border: 'none',
  }),
};

type OnChangeSingle<T> = (data: T) => any;
type OnChangeMulti<T> = (data: T[]) => any;

type OnCreateSingle<T> = (data: T & { __isNew__: true }) => any;
type OnCreateMulti<T> = (data: (T & { __isNew__: true })[]) => any;

type ReactAsyncSelectProps<T> = React.ComponentProps<typeof AsyncSelect<T>>;

interface CustomAsyncAutocompleteProps<T> {
  components?: any;
  defaultValue: any;
  onChange: OnChangeMulti<T> | OnChangeSingle<T>;
  labelField: keyof T | ((data: T) => any);
  valueField?: keyof T | ((data: T) => any);
  isClearable?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  cacheOptions?: boolean;
  queryFn: any;
  queryParams?: any;
  className?: string;
  onCreate?: OnCreateMulti<T> | OnCreateSingle<T>;
}
export type AsyncAutocompleteProps<T> = CustomAsyncAutocompleteProps<T> & Omit<ReactAsyncSelectProps<T>, 'isMulti'>;

const AsyncAutocomplete = <T,>({
  valueField = '_id' as keyof T,
  labelField,
  isClearable = true,
  placeholder,
  queryFn,
  queryParams,
  defaultValue,
  value: unusedValue,
  isMulti = false,
  onChange,
  onCreate,
  className,
  ...props
}: AsyncAutocompleteProps<T>) => {
  const [q, setQ] = React.useState('');
  const [value, setValue] = React.useState<any>(null);
  const [values, setValues] = React.useState<T[]>([]);
  const [asyncFirstCallLoaded, toggleAsyncFirstCallLoaded] = useToggle(false);

  const getValueField: ReactAsyncSelectProps<T>['getOptionValue'] = React.useCallback(
    (option: T) => {
      if (typeof option === 'string' && Array.isArray(value)) {
        option = value.find(({ id }) => id === option);
      }

      return typeof valueField === 'function' ? valueField(option) : !option ? null : typeof valueField === 'string' ? option[valueField] : option;
    },
    [valueField, value]
  );

  const getLabelField: ReactAsyncSelectProps<T>['getOptionLabel'] = React.useCallback(
    (option: T) => {
      if (typeof option === 'string' && Array.isArray(value)) {
        option = value.find(({ id }) => id === option);
      }
      return typeof labelField === 'function' ? labelField(option) : !option ? null : typeof labelField === 'string' ? option[labelField] : option;
    },
    [labelField, value]
  );

  const { loading, refetch } = useQuery(queryFn, {
    variables: q ? { q, ...queryParams } : {},
  });

  const loadOptions = React.useCallback(
    async (searchTerm: string) => {
      setQ(searchTerm);

      const result = await refetch({ q: searchTerm });
      const items = [...result.data.items.items];

      return items || [];
    },
    [refetch]
  );

  React.useEffect(() => {
    if (!defaultValue) {
      return;
    }
    if (Array.isArray(defaultValue) && defaultValue.length === 0) {
      return;
    }
    if (asyncFirstCallLoaded) {
      return;
    }

    const loadDefaultOptions = async () => {
      const result = await refetch({ q: '' });
      const items = [...result.data.items.items];

      if (Array.isArray(defaultValue)) {
        const defaultValues: T[] = await Promise.all(
          defaultValue.map(async (defaultVal) => {
            const existsInResult = items.find((item: any) => item._id === defaultVal);

            if (!existsInResult) {
              const res = await refetch({ q: defaultVal });
              return res.data?.items?.items[0];
            }
            return existsInResult;
          })
        );
        setValues(defaultValues);
      } else {
        const defaultValueId = defaultValue?.id || defaultValue;
        if (defaultValueId && !asyncFirstCallLoaded) {
          const existsInResult = result.data.items.items.find(({ id }: any) => id === defaultValueId);
          if (!existsInResult) {
            // toggleAsyncFirstCallLoaded();
            const defaultRes = await refetch({ q: defaultValueId });
            const defaultItem = defaultRes.data?.items?.items[0];
            setValue(defaultItem);
          } else {
            setValue(existsInResult);
          }
        }
      }
    };

    toggleAsyncFirstCallLoaded();
    loadDefaultOptions();
  }, [defaultValue, asyncFirstCallLoaded, refetch, toggleAsyncFirstCallLoaded]);

  const onChangeSingle: ReactAsyncSelectProps<T>['onChange'] = (newValue) => {
    if (onCreate && (newValue as any)?.__isNew__) {
      return (onCreate as OnCreateSingle<T>)(newValue as T & { __isNew__: true });
    }
    setValue(newValue);
    (onChange as OnChangeSingle<T>)(newValue as T);
  };

  const onChangeMulti: ReactAsyncSelectProps<T>['onChange'] = (newValue) => {
    const newValues = (newValue || []) as T[];
    if (onCreate && ((newValue as any)?.[0] as any)?.__isNew__) {
      return (onCreate as OnCreateMulti<T>)(newValue as (T & { __isNew__: true })[]);
    }
    setValues(newValues);
    (onChange as OnChangeMulti<T>)(newValues);
  };

  const AsyncSelectComponent = onCreate ? AsyncSelectCreatable : AsyncSelect;

  return (
    <AsyncSelectComponent
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      styles={styles}
      isLoading={loading}
      isClearable={isClearable}
      isMulti={isMulti as any}
      getOptionLabel={getLabelField}
      getOptionValue={getValueField}
      onChange={(isMulti ? onChangeMulti : onChangeSingle) as any}
      value={isMulti ? values : value}
      placeholder={placeholder || 'Tape quelques lettres pour chercher'}
      noOptionsMessage={({ inputValue }) =>
        inputValue === '' ? (
          'Tape quelques lettres pour chercher'
        ) : (
          <>
            Aucune option trouvée pour <strong>{inputValue}</strong>
          </>
        )
      }
      menuPortalTarget={typeof document !== 'undefined' ? document.querySelector('body') : undefined}
      classNames={{
        control: (_state) => 'react-select__control',
        input: (_state) => 'react-select__input',
      }}
      className={classNames('react-select__async-autocomplete', className)}
      {...props}
    />
  );
};

export default AsyncAutocomplete;
