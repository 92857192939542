import React from 'react';
import { Upload } from 'modules/upload';
import { MdAttachFile as IconAttachment } from 'react-icons/md';
import { Controller } from 'react-hook-form';

export type UploadInputProps = { control: any; name: string } & Omit<React.ComponentProps<typeof Upload>, 'onUpload'>;
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'svg', 'tiff'];

const MediaRenderer = ({ url }: { url: string }) => {
  if (!url) {
    return null;
  }

  const { pathname } = new URL(url);
  const fileExtension = pathname.split('.').pop()?.toLowerCase();

  if (fileExtension && imageExtensions.includes(fileExtension)) {
    return <img src={url} alt={pathname} className="object-cover w-full h-full" />;
  }

  return (
    <div className="flex gap-2 items-center">
      {fileExtension === 'pdf' && <IconAttachment />}
      <a href={url} target="_blank" rel="noreferrer" className="link">
        {pathname}
      </a>
    </div>
  );
};

const UploadInput: React.FC<UploadInputProps> = ({ control, name, filename, children, accept, folder }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Upload onUpload={(url: string) => field.onChange(url)} accept={accept} folder={folder} filename={filename}>
            {children || <MediaRenderer url={field.value} />}
          </Upload>
        );
      }}
    />
  );
};

export default UploadInput;
