import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import { usePresignedUpload } from 'next-s3-upload';
export { getImageData } from 'next-s3-upload';

const DEFAULT_FOLDER = '_tmp';

const useUpload = () => {
  const { uploadToS3, ...rest } = usePresignedUpload();
  const { bucketsMapping } = usePublicRuntimeConfig();
  const upload = async (file: File, { folder, filename, ...options }: { folder: string; filename: string; headers?: any; body?: any }) => {
    const values = await uploadToS3(file, {
      endpoint: {
        request: {
          body: {
            filenamePath: filename,
            folder: folder || DEFAULT_FOLDER,
            ...(options.headers || {}),
          },
          headers: {
            ...(options.headers || {}),
          },
        },
      },
    });
    Object.entries(bucketsMapping).map(([key, mapping]: any) => {
      values.url = values.url.replace(key, mapping);
    });

    return values;
  };
  return { upload, ...rest };
};

export default useUpload;
