import dayjs from 'dayjs';

import { Controller } from 'react-hook-form';

function DateTimeInput({ control, name }: any) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={new Date()}
      render={({ field }) => (
        <input {...field} type="datetime-local" value={dayjs(field.value).format('YYYY-MM-DDTHH:mm')} onChange={(e) => field.onChange(e)} />
      )}
    />
  );
}

export default DateTimeInput;
