import React from 'react';
import { type FieldError } from 'react-hook-form';

export interface IInputWrapper {
  label?: any;
  children: any;
  help?: any;
  optional?: boolean;
  inline?: boolean;
  error?: string | FieldError;
}
// const Optional = styled('span')`
//   ${({ theme }) => css`
//     color: ${theme.colors.coolGrey};
//     text-transform: uppercase;
//     font-weight: 500;
//     font-size: 10px;
//     letter-spacing: 1px;
//     margin-left: 10px;
//   `}
// `;

// const WrapperInline = styled('div').attrs({
//   className: 'd-flex flex-row-reverse align-items-center justify-content-end',
// })`
//   label {
//     margin: 0;
//     margin-left: 5px;
//   }
// `;

const InputWrapper = ({ label, children, help, error, optional = false }: IInputWrapper & React.HTMLAttributes<HTMLDivElement>) => {
  // const Wrapper = inline ? WrapperInline : React.Fragment;

  return (
    <div>
      <>
        {label && (
          <label>
            {label}
            {optional && (
              <>
                {' '}
                <span className="uppercase font-bold">optional</span>
              </>
            )}
          </label>
        )}
        {children}
      </>
      {error && <div className="text-destructive text-sm mt-1">{typeof error === 'string' ? error : error?.message || error?.type}</div>}
      {help && <small className="mt-1">{help}</small>}
    </div>
  );
};

export default InputWrapper;
